import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Grid, Paper, TextField, Button, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { sendDataToBackend, fetchUserDetails } from '../../../api/serverRequests';
import DefaultImage from '../../../assets/images/lead_logos/default.png';

const useStyles = makeStyles((theme) => ({
	root: {
		overflowX: 'hidden',
		width: '100%'
	},
	container: {
		padding: theme.spacing(2),
		margin: '0 auto',
		maxWidth: '800px',
		width: '100%'
	},
	paper: {
		padding: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	image: {
		width: '100%',
		height: 'auto',
		display: 'block',
		marginBottom: theme.spacing(3),
		objectFit: 'contain'
	},
	textField: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	formWrapper: {
		marginTop: theme.spacing(2)
	},
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		textAlign: 'center'
	},
	sectionTitle: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1)
	},
	submit: {
		marginTop: theme.spacing(3),
		backgroundColor: '#004F2B',
		width: '200px',
		height: '50px',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		color: '#FFFFFF',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: 'auto'
		}
	}
}));

export default function LeadCapture() {
	const classes = useStyles();
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors }
	} = useForm();
	const [orgLogo, setOrgLogo] = useState(null);
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [userId, setUserId] = useState('');
	const [homeId, setHomeId] = useState('');
	const [orgId, setOrgId] = useState('');
	const [submissionSuccess, setSubmissionSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const validateName = (value) => {
		const nameRegex = /^[a-zA-Z\s'-]+$/;
		return nameRegex.test(value) || 'Invalid name format';
	};

	const validatePhoneNumber = (value) => {
		const phoneRegex = /^(\(\d{3}\)\s?|\d{3}[-\s]?)?\d{3}[-\s]?\d{4}$/;
		return phoneRegex.test(value) || 'Invalid phone number format';
	};

	const validateEmail = (value) => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(value) || 'Invalid email address format';
	};

	const onSubmit = (data) => {
		console.log('Form Data Submitted:', data);

		const fullData = {
			City: city,
			Address: address,
			UserId: userId,
			OrganizationId: orgId,
			HomeId: homeId,
			Data: ['name: ' + data.name, 'phone:' + data.phoneNumber, 'email:' + data.emailAddress]
		};

		sendDataToBackend(fullData)
			.then((response) => {
				console.log('Data sent successfully:', response);
				setSubmissionSuccess(true);
				setErrorMessage('');
			})
			.catch((error) => {
				console.error('Error sending data:', error);
				setErrorMessage('Error submitting form. Please try again.');
				setSubmissionSuccess(false);
			});
	};

	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const address = queryParams.get('address');
		const city = queryParams.get('city');
		const userId = queryParams.get('userid');
		const homeId = queryParams.get('homeId');
		const orgId = queryParams.get('orgId');

		if (address) setAddress(address);
		if (city) setCity(city);
		if (userId) setUserId(userId);
		if (homeId) setHomeId(homeId);
		if (orgId) setOrgId(orgId);

		if (orgId || userId) {
			fetchUserDetails(orgId, userId)
				.then((res) => {
					if (res.data.success) {
						const userDetails = res.data.user_details;
						const logoPath = require('../../../assets/images/lead_logos/' +
							userDetails.logo);
						setOrgLogo(logoPath);
					}
				})
				.catch((err) => {
					console.error('Error fetching user details:', err);
				});
		}

		if (city && address) {
			setValue('city', city);
			setValue('address', address);
			sendDataToBackend({
				City: city,
				Address: address,
				UserId: userId,
				OrganizationId: orgId,
				HomeId: homeId
			})
				.then((data) => console.log(data))
				.catch((error) => console.error('Error:', error));
		}
	}, [location, setValue]);

	return (
		<Box className={classes.root}>
			<Grid container justifyContent="center" className={classes.container}>
				<Paper className={classes.paper}>
					<img
						src={orgLogo ? orgLogo : DefaultImage}
						alt="Lead Capture"
						className={classes.image}
					/>
					<Typography variant="h5" component="h2" className={classes.title}>
						Free Home Evaluation Form
					</Typography>

					{/* Display Success Message */}
					{submissionSuccess && (
						<Typography
							variant="h6"
							style={{ color: 'green', textAlign: 'center', marginTop: '20px' }}
						>
							Form submitted successfully!
						</Typography>
					)}

					{/* Display Error Message */}
					{errorMessage && (
						<Typography
							variant="h6"
							style={{ color: 'red', textAlign: 'center', marginTop: '20px' }}
						>
							{errorMessage}
						</Typography>
					)}

					{/* Conditionally Render Form */}
					{!submissionSuccess && (
						<div className={classes.formWrapper}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Grid container spacing={2}>
									{/* Address Details */}
									<Grid item xs={12}>
										<Typography variant="h6" className={classes.sectionTitle}>
											Address Details:
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Controller
											name="address"
											control={control}
											defaultValue=""
											render={({ onChange, value }) => (
												<TextField
													id="addressValue"
													className={classes.textField}
													label="Street Address"
													required
													value={value}
													variant="outlined"
													onChange={(e) => onChange(e.target.value)}
													disabled
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Controller
											name="city"
											control={control}
											defaultValue=""
											render={({ onChange, value }) => (
												<TextField
													id="cityInformation"
													className={classes.textField}
													label="City"
													required
													value={value}
													variant="outlined"
													onChange={(e) => onChange(e.target.value)}
													disabled
												/>
											)}
										/>
									</Grid>
									{/* Contact Details */}
									<Grid item xs={12}>
										<Typography variant="h6" className={classes.sectionTitle}>
											Contact Details:
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Controller
											name="name"
											control={control}
											defaultValue=""
											rules={{ validate: validateName }}
											render={({ onChange, value }) => (
												<TextField
													id="name"
													className={classes.textField}
													label="Name"
													required
													error={!!errors.name}
													helperText={
														errors.name ? errors.name.message : ''
													}
													value={value}
													variant="outlined"
													onChange={(e) => onChange(e.target.value)}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Controller
											name="emailAddress"
											control={control}
											defaultValue=""
											rules={{ validate: validateEmail }}
											render={({ onChange, value }) => (
												<TextField
													id="emailAddress"
													className={classes.textField}
													label="Email Address"
													error={!!errors.emailAddress}
													required
													helperText={
														errors.emailAddress
															? errors.emailAddress.message
															: ''
													}
													value={value}
													variant="outlined"
													onChange={(e) => onChange(e.target.value)}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Controller
											name="phoneNumber"
											control={control}
											defaultValue=""
											rules={{
												validate: (value) =>
													value === '' ||
													validatePhoneNumber(value) === true ||
													validatePhoneNumber(value)
											}}
											render={({ onChange, value }) => (
												<TextField
													id="phoneNumber"
													className={classes.textField}
													label="Phone Number"
													error={!!errors.phoneNumber}
													helperText={
														errors.phoneNumber
															? errors.phoneNumber.message
															: ''
													}
													value={value}
													variant="outlined"
													onChange={(e) => onChange(e.target.value)}
												/>
											)}
										/>
									</Grid>
									{/* Submit Button */}
									<Grid item xs={12}>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											className={classes.submit}
										>
											Submit
										</Button>
									</Grid>
								</Grid>
							</form>
						</div>
					)}
				</Paper>
			</Grid>
		</Box>
	);
}
